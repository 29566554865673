<template>
  <div
    class="summary"
    v-if="Object.keys(memberProfileTableJson).length != 0"
    :id="'member-result-table-' + componentDataIndex"
  >
    <div class="summary-table">
      <p class="summary-table-header">Summary</p>
      <table id="summary-table-1">
        <tr>
          <td class="summary-table-td-main">Demographic Risk Factor</td>
          <td class="summary-table-td">
            {{ memberProfileTableJson.raf.demo_score | roundOff }}
          </td>
        </tr>
        <tr>
          <td class="summary-table-td-main">HCC Risk factor</td>
          <td class="summary-table-td">
            {{ memberProfileTableJson.raf.hcc_score | roundOff }}
          </td>
        </tr>
        <tr>
          <td class="summary-table-td-main">Total Risk Score</td>
          <td class="summary-table-td">
            {{ memberProfileTableJson.raf.score | roundOff }}
          </td>
        </tr>
        <tr>
          <td class="summary-table-td-main">Total Monthly Premium</td>
          <td class="summary-table-td">
            {{ memberProfileTableJson.raf.premium | toCurrency }}
          </td>
        </tr>
      </table>
    </div>
    <div>
      <table id="summary-table-2">
        <tr>
          <th class="member-inner-th-individual" style="width:10%">HCC</th>
          <th class="member-inner-th-individual" style="width:24%">
            HCC Description
          </th>
          <th class="member-inner-th-individual" style="width:10%">
            Dx Code
          </th>
          <th class="member-inner-th-individual" style="width:24%">
            Dx Description
          </th>
          <th class="member-inner-th-individual" style="width:10%">
            Trumped By
          </th>
          <th class="member-inner-th-individual" style="width:10%">
            RAF
            <v-icon
              color="white"
              class="p-1"
              size="12"
              dark
              v-tippy="{
                theme: 'epicc',
                arrow: true,
                content:
                  'Discolored RAF value shows that it is excluded from the final calculation due to trumping',
              }"
              >fas fa-question-circle</v-icon
            >
          </th>
          <th class="member-header-th-right member-inner-th-individual">
            Monthly Premium
            <v-icon
              color="white"
              class="p-1"
              size="12"
              dark
              v-tippy="{
                theme: 'epicc',
                arrow: true,
                content:
                  'Discolored premium value shows that it is excluded from the final calculation due to trumping',
              }"
              >fas fa-question-circle</v-icon
            >
          </th>
        </tr>
        <tr v-for="(hcc, index) in memberProfileTableJson.dx_hccs" :key="index">
          <td>{{ hcc.hcc_name }}</td>
          <td>{{ hcc.hcc_desc }}</td>
          <td>
            <div v-if="hcc.dx_list.length == 0">
              <p class="member-inner-center-td">--</p>
            </div>
            <div v-for="(dx, dxIndex) in hcc.dx_list" :key="dxIndex">
              <p class="member-inner-left-td">{{ dx.dx_name }}</p>
              <span v-if="dxIndex < hcc.dx_list.length - 1">
                <hr class="hr-style-base" />
              </span>
            </div>
          </td>
          <td>
            <div v-if="hcc.dx_list.length == 0">
              <p class="member-inner-center-td">--</p>
            </div>
            <div v-for="(dx, dxIndex) in hcc.dx_list" :key="dxIndex">
              <p class="member-inner-left-td">{{ dx.dx_desc }}</p>
              <span v-if="dxIndex < hcc.dx_list.length - 1">
                <hr class="hr-style-base" />
              </span>
            </div>
          </td>
          <td>
            <div v-if="hcc.trumped_by.length == 0">
              <p class="member-inner-center-td">--</p>
            </div>
            <div v-else>
              <p class="member-inner-left-td">
                {{ hcc.trumped_by.join(', ') }}
              </p>
            </div>
          </td>
          <td>
            <p
              class="member-inner-right-td"
              :style="{ color: hcc.trumped_by.length > 0 ? '#aaa' : '' }"
            >
              {{ hcc.hcc_raf | roundOff }}
            </p>
          </td>
          <td>
            <p
              class="member-inner-right-td"
              :style="{ color: hcc.trumped_by.length > 0 ? '#aaa' : '' }"
            >
              {{ hcc.premium | toCurrency }}
            </p>
          </td>
        </tr>
        <tr v-if="memberProfileTableJson.dx_hccs.length < 1">
          <td class="member-no-code-td" colspan="6" align="center">
            No Codes Entered
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<style scoped src="../../../assets/css/membertable.css"></style>

<script>
import store from '../../../store'
export default {
  props: {
    componentDataIndex: Number,
  },
  computed: {
    memberProfileTableJson: function() {
      return store.getters.getComponentDataByIndex(this.componentDataIndex)
        .responseData.data
    },
  },
  data() {
    return {}
  },
}
</script>
