var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"patch-notes-container"},[_c('v-card',[_c('v-btn',{staticClass:"close-btn-search",style:({
        display: 'block',
        marginTop: '12px',
        marginRight: '-5px',
        zIndex: '1000000',
      }),attrs:{"small":"","fab":"","right":"","absolute":""},on:{"click":function($event){return _vm.handleToggleModal()}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('headers',{staticClass:"patch-notes-header",attrs:{"source":"patch"}}),_c('div',{staticClass:"each-topic-container",staticStyle:{"margin":"0 2%","padding":"0","padding-top":"10px"}},[_c('p',{staticStyle:{"margin-top":"5px"}},[_c('b',{staticClass:"map-1"},[_vm._v("* The Excel Add-in \"Risk Adjustment Toolkit\" is undergoing a full refactoring which results in gradual implementations of all the previously existing functions. The refactoring is expected to finish by the mid of January 2025. The notes for the versions deployed before v7.0.0.0 are still kept just in case for viewing the older update records.")])])]),_c('div',{staticClass:"patch-notes-details"},[_c('c-accordion',{attrs:{"allow-multiple":true,"default-index":[0]}},_vm._l((_vm.patchNotes),function(patchNoteItem,i){return _c('c-accordion-item',{key:i,class:['accordion-item acc-item-' + i]},[_c('c-accordion-header',[_c('c-box',{attrs:{"flex":"1","text-align":"left"}},[_c('p',{staticClass:"patch-notes-title"},[_vm._v(" "+_vm._s(patchNoteItem.heading)+" ")]),_c('p',{staticClass:"patch-notes-date"},[_vm._v(_vm._s(patchNoteItem.date))])]),_c('c-accordion-icon')],1),_c('c-accordion-panel',{class:i == 0 && _vm.accordion_drop_state ? 'drop-active' : '',attrs:{"pb":"4"}},[_c('ul',_vm._l((patchNoteItem.patchNote),function(item,index){return _c('li',{key:index,staticClass:"patch-notes-points",domProps:{"innerHTML":_vm._s(_vm.formatMessage(item.text))}})}),0),(
                patchNoteItem.heading == 'Risk Adjustment Toolkit | v2.0.0.8'
              )?_c('p',{staticClass:"imp-msg"},[_c('b',{staticStyle:{"font-weight":"bold"}},[_vm._v("*Please check out the Support Page for further details on the changes.")])]):_vm._e()])],1)}),1),_c('p',{staticClass:"patch-notes-date"}),_c('div',{staticClass:"patch-notes-list"},[_c('p',{staticClass:"rating-msg"},[_c('b',{staticStyle:{"font-weight":"bold","color":"black"}},[_vm._v(" Please "),_c('a',{attrs:{"href":"https://appsource.microsoft.com/en-us/product/office/WA200001947?tab=Reviews#","target":"_blank"}},[_c('span',{staticClass:"rate-and-share"},[_vm._v("rate and share")])]),_vm._v(" your experience. This empowers us to continue maintaining and improving the toolkit. ")])]),_c('div',{staticClass:"dont-show-input-box"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.removePatchToggle),expression:"removePatchToggle"}],staticClass:"dont-show-input",attrs:{"type":"checkbox"},domProps:{"checked":_vm.removePatchToggle,"checked":Array.isArray(_vm.removePatchToggle)?_vm._i(_vm.removePatchToggle,null)>-1:(_vm.removePatchToggle)},on:{"change":[function($event){var $$a=_vm.removePatchToggle,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.removePatchToggle=$$a.concat([$$v]))}else{$$i>-1&&(_vm.removePatchToggle=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.removePatchToggle=$$c}},function($event){return _vm.removePatchPermanently()}]}}),_c('p',{staticClass:"dont-show-input"},[_vm._v("Don't show this again")])])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }