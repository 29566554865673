const patchNotes_17_01_2024 = [
  {
    text: `Changes to the “model” parameter which now takes “RAF Type Codes” corresponding to the [bold]“Monthly Membership Detailed Reports (MMR)”[/bold]. *`,
    id: 1,
  },
  {
    text:
      'The RAF score outputs will be [bold]normalized[/bold], i.e. calculated based on the Normalization Factor and Coding Intensity for [bold]Medicare Advantage[/bold] (Part C) Plans.',
    id: 2,
  },
  {
    text:
      'Change in the sequence of parameters for the [italic]formula based[/italic] approach to ensure consistency across different excel functions.',
    id: 3,
  },
  {
    text: 'Bug fixes involving Interaction Codes and their RAF scores.',
    id: 4,
  },
  {
    text:
      'Bug fixes around Member’s Age/Sex dependent HCCs, majorly in the [bold]“Affordable Care Act” (ACA)[/bold] Plans.',
    id: 5,
  },
]

const patchNotes_05_02_2024 = [
  {
    text:
      'Incorporated ESRD models in Medicare Part C for DOS Years 2020-2023.',
    id: 1,
  },
]
const patchNotes_11_03_2024 = [
  {
    text:
      'Added a toggle for the users to enable them to choose between Normalised and raw RAF Scores.',
    id: 1,
  },
]
const patchNotes_05_09_2024 = [
  {
    text: `Incorporated v28 and v24 models in Medicare Part C for DOS Year 2024.`,
    id: 1,
  },
  {
    text: 'Incorporated v24 model in Medicare Part C for DOS Year 2023.',
    id: 2,
  },
  {
    text: 'Incorporated hhs_v07 Commercial model for DOS Year 2024.',
    id: 3,
  },
]

const patchNotes_18_09_2024 = [
  {
    text: `Incorporated v24 ESRD models in Medicare Part C for DOS Year 2024.`,
    id: 1,
  },
]

const patchNotes_15_12_2024 = [
  {
    text: `Added support for few functions after refactoring of the plugin. Functions supported:<br><span style="font-weight: bold;">CC_COMBINE, CLEAN_DX, CLEAN_CC, CC_DESC, DX_DESC, CC_RAF, DX_RAF, DX2CC, DX_PROFILE_COMPARE, MEMPROFILE</span>`,
    id: 1,
  },
]

const patchNotes_25_12_2024 = [
  {
    text: `Added support for remaining functions left in v7.0.0.0 Functions added in this version:<br>
          <span style="font-weight: bold;">CC_INCREMENT, CC_INCREMENT_RAF, DX_INCREMENT, DX_INCREMENT_RAF, CC_GAPS, CC_GAPS_RAF, DX_GAPS, DX_GAPS_RAF, CC_DIFF, CC_DIFF_RAF, CC_DIFF_ADDS, CC_DIFF_ADDS_RAF, CC_DIFF_DELETES, CC_DIFF_DELETES_RAF, CC_DIFF_UPGRADED, CC_DIFF_DOWNGRADED, CC_DIFF_DOWNGRADE_TO, DX_ACUTE, DX_CHRONIC, DX_HYBRID, CC_ACUTE, CC_CHRONIC, CC_HYBRID, DX_DIFF, DX_DIFF_RAF</span>`,
    id: 1,
  },
]

const patchNotes = [
  {
    patchNote: patchNotes_25_12_2024,
    heading: 'Risk Adjustment Toolkit | v7.0.0.1',
    date: 'December 25, 2024',
  },
  {
    patchNote: patchNotes_15_12_2024,
    heading: 'Risk Adjustment Toolkit | v7.0.0.0',
    date: 'December 15, 2024',
  },
  {
    patchNote: patchNotes_18_09_2024,
    heading: 'Risk Adjustment Toolkit | v4.1.0.0',
    date: 'September 18, 2024',
  },
  {
    patchNote: patchNotes_05_09_2024,
    heading: 'Risk Adjustment Toolkit | v4.0.0.0',
    date: 'September 5, 2024',
  },
  {
    patchNote: patchNotes_11_03_2024,
    heading: 'Risk Adjustment Toolkit | v3.1.1.0',
    date: 'March 11, 2024',
  },
  {
    patchNote: patchNotes_05_02_2024,
    heading: 'Risk Adjustment Toolkit | v3.0.0.0',
    date: 'February 5, 2024',
  },
  {
    patchNote: patchNotes_17_01_2024,
    heading: 'Risk Adjustment Toolkit | v2.0.0.8',
    date: 'January 17, 2024',
  },
]

export default patchNotes
