<template>
  <div style="position: relative">
    <v-form required v-on:submit.prevent>
      <v-text-field
        ref="condition"
        color="#0099d9"
        placeholder="Enter DX/HCC code here"
        v-model="tag"
        @keyup.188="addTag"
        @keyup.186="addTag"
        @keyup.32="addTag"
        @keydown.enter="addTag"
        @keydown.tab="addTag"
        @paste="getPastedCodes"
        v-on:input="debounceInput"
        dense
        outlined
        clear-icon="mdi-close-circle"
        clearable
        @click:clear="onClear"
        class="dxsearchinputbox"
        autocomplete="off"
        :error-messages="
          updateErrorMessage ? ['Enter conditions to proceed'] : []
        "
      >
      </v-text-field>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <button
            type="button"
            :class="searchButtonClass"
            @click.prevent="toggleModal"
            @click="toggleModal(true)"
            v-on="on"
          >
            <v-icon class="search-icon">fa-search</v-icon>
          </button>
        </template>
        <span>Search for DX / HCC</span>
      </v-tooltip>
      <v-dialog v-model="dialog" max-width="80%">
        <search v-model="dialog" v-on:confirmAction="toggleModal(false)" />
      </v-dialog>
    </v-form>
    <div class="box">
      <v-snackbar v-model="snackbar" class="snackbar">
        <div class="snackbar-text">
          {{ errorMessage }}
        </div>
        <v-btn color="pink" text @click="snackbar = false">Close</v-btn>
      </v-snackbar>
      <v-card
        class="overflow-y-auto overflow-x-auto mx-auto condition-box"
        outlined
      >
        <v-card-text>
          <v-chip-group multiple column active-class="primary--text">
            <v-chip
              tabindex="-1"
              ref="textchip"
              class="chip-tag"
              label
              text-color="white"
              v-for="tag in tags"
              :key="tag"
              :id="tag"
              close
              @click:close="remove(tag)"
              v-bind:style="[
                invalidChips.includes(tag)
                  ? { 'background-color': 'red !important' }
                  : notFoundCodes.includes(tag)
                  ? { 'background-color': '#FFBF00 !important' }
                  : { 'background-color': '#008c50 !important' },
              ]"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <span v-on="on" @mouseover="showTagInfo(tag)">{{ tag }}</span>
                </template>
                <span>{{ hoverDesc }}</span>
                <br />
                <span v-if="!isInvalidTag && hoverHcc != 'NA'"
                  >HCC: {{ hoverHcc }} ,</span
                >
                <span v-if="!isInvalidTag">RAF: {{ hoverRaf }}</span>
              </v-tooltip>
            </v-chip>
          </v-chip-group>
        </v-card-text>
      </v-card>
      <chipsIndicator
        :invalidChips="invalidChips"
        :tags="tags"
        :notFoundCodes="notFoundCodes"
      />
    </div>
  </div>
</template>

<script>
import SearchComponent from './SearchComponent.vue'
import api from '../../services/api'
import ChipsIndicator from './ChipsIndicator'
import _ from 'lodash'
var LRU = require('lru-cache')
var options = {
  max: 500,
  length: function(n, key) {
    return n * 2 + key.length
  },
  maxAge: 1000 * 60 * 60,
}
var cache = new LRU(options)
export default {
  cache,
  props: {
    componentDataIndex: Number,
    tagList: Array,
    tagstring: String,
    isClearTag: Boolean,
    riskModel: String,
    dosYearModel: String,
    isEmptyTagList: Boolean,
    commandType: String,
    searchBox: String,
  },
  computed: {
    riskModel_: {
      get: function() {
        return this.riskModel
      },
      set: function(value) {
        this.riskModel = value
      },
    },
    dosYear_: {
      get: function() {
        return this.dosYearModel
      },
      set: function(value) {
        this.dosYearModel = value
      },
    },
    tag_: {
      get: function() {
        return this.tagstring
      },
      set: function(value) {
        this.tagstring = value
      },
    },
    tags: {
      get: function() {
        return this.tagList
      },
      set: function(value) {
        this.tagList = value
      },
    },
    invalidChips_() {
      return this.invalidChips
    },
    isClearTags: {
      get: function() {
        return this.isClearTag
      },
      set: function(value) {
        this.tags = []
        this.isClearTag = value
        this.$emit('clearTagFlag', false)
      },
    },
    updateErrorMessage: {
      get: function() {
        return this.isEmptyTagList
      },
      set: function(value) {
        this.isEmptyTagList = value
      },
    },
    searchButtonClass: {
      get: function() {
        return this.commandType == 'member'
          ? 'member-search-button'
          : 'compare-search-button'
      },
      set: function(value) {
        this.commandType = value
      },
    },
  },
  data() {
    return {
      dialog: false,
      tag: this.tag_,
      tagStringFromPaste: '',
      searchData: [],
      conditionsList: [],
      hoverDesc: 'Loading...',
      hoverRaf: 0,
      hoverHcc: 'Loading...',
      invalidChips: [],
      tagCache: '',
      isInvalidTag: false,
      snackbar: false,
      notFoundCodes: [],
      errorMessage: 'Enter conditions to proceed',
      search_url: '/api/get_codes',
      memberSearchSuggestion: false,
    }
  },

  components: {
    search: SearchComponent,
    chipsIndicator: ChipsIndicator,
  },
  updated() {
    if (this.$refs.textchip != null) {
      this.$refs.textchip.forEach(function($chip) {
        $chip.$children[1].$el.tabIndex = -1 // set chip close-button's Tabindex to -1
      })
    }
  },
  created() {
    if (this.searchBox == 'tag1') {
      this.$root.$refs.CodeInput1 = this
    } else {
      this.$root.$refs.CodeInput2 = this
    }
  },
  methods: {
    debounceInput: _.debounce(function() {
      this.handleChange()
    }, 500),
    onClear() {
      setTimeout(() => {
        this.tag = ''
        this.$store.state.search_member = []
        this.$store.state.search_member1 = []
        this.$store.state.search_suggestion_loader = false
      }, 500)
    },
    toggleModal(value) {
      this.dialog = value
      let SearchedCodeString = (this.searchData = localStorage.getItem(
        'selectedSearchData'
      ))
      if (SearchedCodeString != null) {
        let SearchedCodeList = SearchedCodeString.split(',')
        SearchedCodeList.forEach(
          function(item) {
            if (!this.tags.includes(item) && item.length > 0) {
              this.tags.push(item)
              this.validateAndCacheTag(item)
            }
            this.$emit('getTag', this.tags)
            this.$emit('emptyTagsFlag', false)
          }.bind(this)
        )
        localStorage.removeItem('selectedSearchData')
      }
    },
    isInvalidChar(string) {
      return /^[a-z0-9_]+$/i.test(string) ? false : true
    },
    isInTagList(tag) {
      return this.tags.includes(tag) ? true : false
    },
    convertDecimalCodeToString(string) {
      if (isNaN(string)) {
        string = string.replace('.', '')
      }
      return string
    },
    addHCCPrefix(string) {
      if (!isNaN(Number(string)) && Number(string) != 0) {
        // Number(" ") returns 0, hence used Number(string) != 0
        if (
          this.$store.state.componentData[
            this.componentDataIndex
          ].inputDict.dosYearModel.startsWith('hhs')
        )
          string = 'HHS_HCC' + string
        else string = 'HCC' + string
      }
      return string
    },
    getPastedCodes(pasteEvent) {
      let pastedCode = pasteEvent.clipboardData.getData('text')
      this.tagStringFromPaste =
        this.tagStringFromPaste != ''
          ? this.tagStringFromPaste + ',' + pastedCode
          : pastedCode
    },
    addTagFromList(item) {
      this.tag = item
      let keyevent = {
        key: 'Enter',
      }
      this.addTag(keyevent)
    },
    addTag(keyevent) {
      this.tag =
        typeof this.tag != 'undefined'
          ? this.addHCCPrefix(
              this.convertDecimalCodeToString(this.tag.toUpperCase())
            )
          : ''
      if (
        (keyevent.key == 'Enter' || keyevent.key == 'Tab') &&
        !this.isInvalidChar(this.tag) &&
        !this.isInTagList(this.tag) &&
        this.tagStringFromPaste == ''
      ) {
        this.tags.push(this.tag)
        this.validateAndCacheTag(this.tag)
        this.$emit('getTag', this.tags)
        this.$emit('emptyTagsFlag', false)
      } else if (this.tagStringFromPaste != '') {
        this.createTagOnPaste(this.tagStringFromPaste)
        this.tag_ = ''
        this.tag = ''
      }
      let templist = this.tag.split(keyevent.key)
      for (let tempTag in templist) {
        // for accepting DX codes in decimal format as well
        templist[tempTag] = this.addHCCPrefix(
          this.convertDecimalCodeToString(templist[tempTag])
        )
      }
      for (let i = 0; i < templist.length - 1; i++) {
        if (
          !this.isInvalidChar(templist[i]) &&
          !this.isInTagList(templist[i])
        ) {
          this.tags.push(templist[i])
          this.validateAndCacheTag(templist[i])
        }
        this.$emit('getTag', this.tags)
        this.$emit('emptyTagsFlag', false)
      }
      this.tag = ''
      this.$store.state.search_member = []
      this.$store.state.search_member1 = []
    },
    createTagOnBlur() {
      this.addTag({ key: 'Enter' })
    },
    removedNotFoundCodes(item) {
      var codesAfterRemoval = this.notFoundCodes.filter(function(value) {
        return value != item
      })
      this.notFoundCodes = codesAfterRemoval
    },
    removeInvalidChips(item) {
      var invalidChipsAfterRemoval = this.invalidChips.filter(function(value) {
        return value != item
      })
      this.invalidChips = invalidChipsAfterRemoval
    },
    remove(item) {
      const index = this.tags.indexOf(item)
      if (index >= 0) {
        this.tags.splice(index, 1)
        this.removeInvalidChips(item)
        this.removedNotFoundCodes(item)
        this.$emit('removeTag', this.tags)
      }
    },
    revalidateTags(dos) {
      this.invalidChips = []
      this.notFoundCodes = []
      this.tags.map((tag) => {
        this.validateAndCacheTag(tag, dos)
      })
    },
    resetInvalidChips() {
      this.invalidChips = []
      this.notFoundCodes = []
    },
    validateAndCacheTag(tag, ...restArgs) {
      let version = restArgs[0] ? restArgs[0] : this.dosYearModel
      let parameter = { code: tag, version: version }
      let cacheKey = `${version}:${tag}`
      if (!(cacheKey in cache.keys())) {
        api
          .getHoverData(parameter)
          .then((response) => {
            cache.set(cacheKey, response.data)
            this.tagCache = cache.get(cacheKey)
            if (
              !this.tagCache.available &&
              !this.tagCache.hasOwnProperty('available_in_other_version')
            ) {
              this.invalidChips.push(tag)
            }
            if (
              this.tagCache.hasOwnProperty('available_in_other_version') &&
              this.tagCache.available_in_other_version
            ) {
              this.notFoundCodes.push(response.data.code)
            }
            this.$emit('invalidChips', this.invalidChips)
            this.$emit('codesNotFound', this.notFoundCodes)
          })
          .catch(
            function() {
              this.hoverDesc = 'Loading...'
              this.hoverRaf = 0
              this.hoverHcc = 'Loading...'
            }.bind(this)
          )
        this.$emit('invalidChips', this.invalidChips)
      }
    },

    showTagInfo(tag) {
      let onHoverTagInfo = cache.get(`${this.dosYearModel}:${tag}`)
      if (typeof onHoverTagInfo != 'undefined') {
        this.isInvalidTag = onHoverTagInfo.available == false ? true : false
        this.hoverDesc = !onHoverTagInfo.available
          ? 'Invalid Code'
          : onHoverTagInfo.description
        let riskModelValue = this.riskModel_
        let ageValue = this.$store.state.componentData[this.componentDataIndex]
          .inputDict.age
        if (
          this.riskModel_ == 'CN' ||
          this.riskModel_ == 'CP' ||
          this.riskModel_ == 'CF'
        ) {
          riskModelValue =
            ageValue >= 65 ? this.riskModel_ + 'A' : this.riskModel_ + 'D'
        } else if (this.riskModel_ == 'D1') {
          riskModelValue =
            'C' +
            ((ageValue || ageValue === 0 || ageValue === '0' || 65) < 65
              ? 'N'
              : 'A') +
            'N'
        } else if (this.riskModel_ == 'D2') {
          ;(riskModelValue =
            'CL' +
            ((ageValue || ageValue === 0 || ageValue === '0' || 65) < 65
              ? 'N'
              : 'A')),
            false
        } else if (this.riskModel_ == 'D3') {
          riskModelValue = 'INS'
        } else if (this.riskModel_ == 'D4' || this.riskModel_ == 'D5') {
          riskModelValue = 'NEN'
        } else if (this.riskModel_ == 'D6' || this.riskModel_ == 'D7') {
          riskModelValue = 'NEL'
        } else if (this.riskModel_ == 'D8' || this.riskModel_ == 'D9') {
          riskModelValue = 'NEI'
        } else if (this.riskModel_ == 'C1' || this.riskModel_ == 'C2') {
          riskModelValue = 'CG'
        } else if (this.riskModel_ == 'D') {
          riskModelValue = 'CED'
        } else if (this.riskModel_ == 'I1' || this.riskModel_ == 'I2') {
          riskModelValue = 'IG'
        } else if (this.riskModel_ == 'G1' || this.riskModel_ == 'G2') {
          riskModelValue = 'KT'
        } else if (this.riskModel_ == 'ED') {
          riskModelValue = 'NED'
        } else if (this.riskModel_ == 'E1') {
          riskModelValue = 'E1'
        } else if (this.riskModel_ == 'E2') {
          riskModelValue = 'E2'
        }
        // This condition is checked because from backend these versions are coming as v22 and v23
        // We cannot change this entire mapping in the frontend because of member api that is using these complete versions
        if (this.dosYear_ == 'v22_2017' || this.dosYear_ == 'v23_2018') {
          this.dosYear_ = this.dosYear_.split('_')[0]
        }
        this.hoverRaf = !onHoverTagInfo.available
          ? ''
          : onHoverTagInfo[this.dosYear_][riskModelValue]
        if (!onHoverTagInfo.code.includes('HCC')) {
          this.hoverHcc = !onHoverTagInfo.available
            ? ''
            : onHoverTagInfo.hcc.join(',')
        } else {
          this.hoverHcc = 'NA'
        }
      } else {
        this.validateAndCacheTag(tag)
      }
    },
    splitStringAndGetValidTags(string, separators = [' ', ',', ':', ';']) {
      if (string != '' || !/\s/g.test(string)) {
        let tempChar = separators[0] // We can use the first token as a temporary join character
        separators.forEach((separator) => {
          string = string.split(separator).join(tempChar)
        })
        let validTags = []
        string.split(tempChar).forEach((el) => {
          el = this.addHCCPrefix(this.convertDecimalCodeToString(el))
          if (!this.isInvalidChar(el)) {
            validTags.push(el)
          }
          if (validTags.length == 0) {
            this.errorMessage = 'The pasted codes have invalid characters.'
            this.snackbar = true
          }
        })
        return validTags
      }
    },
    createTagOnPaste(tagStringFromPaste) {
      let tagListFromPaste = this.splitStringAndGetValidTags(tagStringFromPaste)
      tagListFromPaste.forEach((pastedTag) => {
        pastedTag = pastedTag.trim().toUpperCase()
        if (!this.isInvalidChar(pastedTag) && !this.isInTagList(pastedTag)) {
          this.validateAndCacheTag(pastedTag)
          this.tagList.push(pastedTag)
        }
      })
      this.$emit('getTag', this.tags)
      this.tagStringFromPaste = ''
    },
    handleChange() {
      this.$store.state.search_box = this.searchBox
      this.$store.state.search_suggestion_loader = true
      if (this.tag.length < 2) {
        this.$store.state.search_member = []
        this.$store.state.search_member1 = []
        this.$store.state.search_box = ''
        this.$store.state.search_suggestion_loader = false
        return
      }
      let val =
        !isNaN(this.tag) ||
        this.tag.substring(0, 2) == 'HC' ||
        this.tag.substring(0, 2) == 'RX' ||
        this.tag.substring(0, 2) == 'HH' ||
        this.tag.substring(0, 2) == 'hc' ||
        this.tag.substring(0, 2) == 'rx' ||
        this.tag.substring(0, 2) == 'hh' ||
        ['37.1', '37.2'].includes(this.tag)
          ? 'hcc'
          : 'dx'

      let search_data = {
        dx_hcc: val,
        dos_year: this.dosYearModel,
        drf: this.returnSearchDrfValue(),
        search: this.tag,
        start: 0,
      }
      this.axios.post(this.search_url, search_data).then((response) => {
        this.$store.state.search_suggestion_loader = false
        this.$store.state.search_member = []
        this.$store.state.search_member1 = []
        if (this.tag != '') {
          for (let i = 0; i < response.data.length; i++) {
            let item = response.data[i].code
            this.$store.state.search_member.push(item)
          }
        }
      })
    },
    handleClickOutside() {
      // Check if click occurred outside of modal container
      this.tag = ''
      this.$store.state.search_member = []
      this.$store.state.search_member1 = []
      this.$store.state.search_suggestion_loader = false
    },
    returnSearchDrfValue() {
      let defaultDrf = this.$store.state.componentData[this.componentDataIndex]
        .inputDict.riskModel
      let finalDrfValue = ''
      let search_v21_esrd = {
        C1: 'CG',
        C2: 'CG',
        D: 'CED',
        I2: 'IG',
        G1: 'KT',
        G2: 'KT',
        ED: 'NED',
        E2: 'E2',
      }
      let search_v24_esrd = {
        C3: parseInt(this.age) < 65 ? 'GFD' : 'GFA',
        C4: parseInt(this.age) < 65 ? 'GND' : 'GNA',
        C5: parseInt(this.age) < 65 ? 'GND' : 'GNA',
        C6: parseInt(this.age) < 65 ? 'GFD' : 'GFA',
        C7: parseInt(this.age) < 65 ? 'GND' : 'GNA',
        C8: parseInt(this.age) < 65 ? 'GND' : 'GNA',
        D1: 'CED',
        D2: 'CED',
        ED: 'NED',
        E1: 'E1',
        E2: 'E2',
        G1: 'KT',
        G2: 'KT',
        I3: 'CEDI',
        I4: 'CEDI',
        I5: 'GI',
        I6: 'GI',
        I7: 'GI',
        I8: 'GI',
        I9: 'GI',
        IA: 'GI',
      }
      let search_v05 = {
        D1: parseInt(this.age) < 65 ? 'CNN' : 'CAN',
        D2: parseInt(this.age) < 65 ? 'CLN' : 'CLA',
        D3: 'INS',
        D4: 'NEN',
        D5: 'NEN',
        D6: 'NEL',
        D7: 'NEL',
        D8: 'NEI',
        D9: 'NEI',
      }
      let search_vData = {
        CN: parseInt(this.age) < 65 ? 'CND' : 'CNA',
        CP: parseInt(this.age) < 65 ? 'CPD' : 'CPA',
        CF: parseInt(this.age) < 65 ? 'CFD' : 'CFA',
        I: 'INS',
      }
      let search_pace_v_Data = {
        PK: parseInt(this.age) < 65 ? 'PKD' : 'PKA',
        PI: parseInt(this.age) < 65 ? 'PID' : 'PIA',
        PJ: parseInt(this.age) < 65 ? 'PJD' : 'PJA',
        I: 'INS',
        E: 'NE',
        SE: 'SNPNE',
      }
      let search_pace_v21_esrd = {
        PA: 'CED',
        PB: 'NED',
        PC: 'CG',
        PF: 'CG',
        PD: 'IG',
        PG: 'IG',
        PL: 'KT',
        PM: 'KT',
        PE: 'E1',
        PH: 'E2',
      }
      let search_pace_v05 = {
        P1: 'NEL',
        P2: 'NEN',
        P3: 'NEI',
        P4: 'NEI',
        P5: 'NEN',
        P6: 'NEN',
        P7: parseInt(this.age) < 65 ? 'CNN' : 'CAN',
        P8: parseInt(this.age) < 65 ? 'CLN' : 'CLA',
        P9: 'INS',
      }
      if (defaultDrf in search_vData) {
        finalDrfValue = search_vData[defaultDrf]
      } else if (defaultDrf in search_v05) {
        finalDrfValue = search_v05[defaultDrf]
      } else if (defaultDrf in search_pace_v05) {
        finalDrfValue = search_pace_v05[defaultDrf]
      } else if (defaultDrf in search_pace_v21_esrd) {
        finalDrfValue = search_pace_v21_esrd[defaultDrf]
      } else if (defaultDrf in search_pace_v_Data) {
        finalDrfValue = search_pace_v_Data[defaultDrf]
      } else if (defaultDrf in search_v21_esrd) {
        finalDrfValue = search_v21_esrd[defaultDrf]
      } else if (defaultDrf in search_v24_esrd) {
        finalDrfValue = search_v24_esrd[defaultDrf]
      } else {
        finalDrfValue = defaultDrf
      }
      return finalDrfValue
    },
  },
  mounted() {
    // Add event listener for clicks on document
    document.addEventListener('click', this.handleClickOutside)
  },
  beforeDestroy() {
    // Remove event listener when component is destroyed
    document.removeEventListener('click', this.handleClickOutside)
  },
}
</script>
<style>
.snackbar > div > div {
  display: flex;
  align-content: center;
  justify-content: space-around;
}
.snackbar-text {
  display: flex;
  align-items: center;
  justify-content: center;
}
.member-search-suggestion {
  width: 86%;
  background-color: white;
  height: auto;
  max-height: 125px;
  overflow: auto;
  position: absolute;
  top: 40px;
  left: 0px;
  padding-left: 0px !important;
  border-radius: 0px 0px 5px 5px;
}
.member-search-suggestion > ul > li {
  list-style: none;
  text-align: left;
  padding-left: 12px;
  cursor: pointer;
  min-height: 40px;
  padding: 0px 16px;
  letter-spacing: normal;
  display: flex;
  align-items: center;
  width: 100%;
}
.member-search-suggestion > ul {
  max-height: 114px;
  width: 100%;
  padding: 0px !important;
}
.member-search-suggestion > ul > li:hover {
  color: white;
  background-color: rgb(84, 84, 84);
}
.suggestion-box {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 10px !important;
}
.member-search-loader {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.show-box {
  min-height: 60px !important;
  text-align: center;
}
</style>
