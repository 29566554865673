<template>
  <div class="patch-notes-container">
    <v-card>
      <v-btn
        v-bind:style="{
          display: 'block',
          marginTop: '12px',
          marginRight: '-5px',
          zIndex: '1000000',
        }"
        small
        class="close-btn-search"
        @click="handleToggleModal()"
        fab
        right
        absolute
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <headers source="patch" class="patch-notes-header" />
      <div
        class="each-topic-container"
        style="margin: 0 2%; padding: 0; padding-top: 10px;"
      >
        <p style="margin-top: 5px;">
          <b class="map-1"
            >* The Excel Add-in "Risk Adjustment Toolkit" is undergoing a full
            refactoring which results in gradual implementations of all the
            previously existing functions. The refactoring is expected to finish
            by the mid of January 2025. The notes for the versions deployed
            before v7.0.0.0 are still kept just in case for viewing the older
            update records.</b
          >
        </p>
      </div>
      <div class="patch-notes-details">
        <c-accordion :allow-multiple="true" :default-index="[0]">
          <c-accordion-item
            v-for="(patchNoteItem, i) in patchNotes"
            :class="['accordion-item acc-item-' + i]"
            :key="i"
          >
            <c-accordion-header>
              <c-box flex="1" text-align="left">
                <p class="patch-notes-title">
                  {{ patchNoteItem.heading }}
                </p>
                <p class="patch-notes-date">{{ patchNoteItem.date }}</p>
              </c-box>
              <c-accordion-icon />
            </c-accordion-header>
            <c-accordion-panel
              :class="i == 0 && accordion_drop_state ? 'drop-active' : ''"
              pb="4"
            >
              <ul>
                <li
                  class="patch-notes-points"
                  v-for="(item, index) in patchNoteItem.patchNote"
                  :key="index"
                  v-html="formatMessage(item.text)"
                ></li>
              </ul>
              <p
                class="imp-msg"
                v-if="
                  patchNoteItem.heading == 'Risk Adjustment Toolkit | v2.0.0.8'
                "
              >
                <b style="font-weight: bold;"
                  >*Please check out the Support Page for further details on the
                  changes.</b
                >
              </p>
            </c-accordion-panel>
          </c-accordion-item>
        </c-accordion>
        <p class="patch-notes-date"></p>

        <div class="patch-notes-list">
          <p class="rating-msg">
            <b style="font-weight: bold; color: black;">
              Please
              <a
                href="https://appsource.microsoft.com/en-us/product/office/WA200001947?tab=Reviews#"
                target="_blank"
                ><span class="rate-and-share">rate and share</span></a
              >
              your experience. This empowers us to continue maintaining and
              improving the toolkit.
            </b>
          </p>
          <div class="dont-show-input-box">
            <input
              class="dont-show-input"
              type="checkbox"
              v-model="removePatchToggle"
              @change="removePatchPermanently()"
              :checked="removePatchToggle"
            />
            <p class="dont-show-input">Don't show this again</p>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>
<style src="../../assets/css/patchNotes.css"></style>
<style>
.drop-active {
  height: 100px !important;
}

.acc-item-1 {
  margin-top: 20px !important;
}
.dont-show-input-box {
  display: flex;
  align-items: left;
  justify-content: left;
  padding-left: 4px;
  text-align: left;
  gap: 4px;
}
</style>
<script>
import Header from './Header.vue'
import patchNotes from '../../data/patchNotesDetails.js'
import {
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionPanel,
  CAccordionIcon,
} from '@chakra-ui/vue'

export default {
  data() {
    return {
      patchNotes: patchNotes,
      removePatchToggle: localStorage.getItem('removePatch') == 'true',
      accordion_drop_state: true,
    }
  },
  methods: {
    handleToggleModal() {
      this.$store.commit('togglePatchNotesModal')
      localStorage.setItem('noOfPatchNotes', this.patchNotes.length)
    },
    formatMessage(message) {
      // Replace [bold] with <b> and [/bold] with </b> for dynamic formatting
      let finalMessage = message
      if (message.includes('italic')) {
        finalMessage = message
          .replace(/\[italic\]/g, '<i>')
          .replace(/\[\/italic\]/g, '</i>')
      }
      if (finalMessage.includes('bold')) {
        finalMessage = finalMessage
          .replace(/\[bold\]/g, '<b style="font-weight: bold !important">')
          .replace(/\[\/bold\]/g, '</b>')
      }

      return finalMessage
    },
    removePatchPermanently() {
      localStorage.setItem('removePatch', this.removePatchToggle)
      localStorage.setItem('noOfPatchNotes', this.patchNotes.length)
    },
  },
  mounted() {
    this.accordion_drop_state = false
  },
  components: {
    headers: Header,
    CAccordion,
    CAccordionItem,
    CAccordionHeader,
    CAccordionPanel,
    CAccordionIcon,
  },
}
</script>
